export const formatBytes = (a, b) => {
  if (a === 0) return '0 Bytes'
  const c = 1024
  const d = b || 2
  const e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const f = Math.floor(Math.log(a) / Math.log(c))
  return `${parseFloat((a / Math.pow(c, f)).toFixed(d))} ${e[f]}`
}

export const getUnique = (arr, comp) => {
  const unique = arr
    .map((e) => e[comp])
    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e])
  return unique
}

export const onlyNumber = (value) => {
  if (value === '' || /^\d+$/.test(value)) {
    return true
  }
  return false
}
export const hideEmail = (email) => {
  const parts = email.split('@')
  const username = parts[0]
  const domain = parts[1]

  const usernameVisible = `${username.substring(0, 4)}****`
  const domainVisible = `***${domain.substring(3)}`

  return `${usernameVisible}@${domainVisible}`
}
export const onKeyPressJustNumbers = (event) => {
  const keyCode = event.keyCode || event.which
  const keyValue = String.fromCharCode(keyCode)
  if (!/^\d+$/.test(keyValue)) {
    event.preventDefault()
  }
}
export const justNumbersDecimal = (event) => {
  const keyCode = event.keyCode || event.which
  const keyValue = String.fromCharCode(keyCode)
  const valueBool = /^[\d.]+$/.test(keyValue)
  if (!valueBool) {
    event.preventDefault()
  }
}
export const onlyDecimalsGreaterThanOne = (value) => {
  if (value === '' || /^(?:[1-9]\d*(?:\.\d+)?|1(?:\.0+)?)$/.test(value)) {
    return true
  }
  return false
}
export const isAlphanumeric = (value) => {
  console.log(value)
  if (value === '' || /^[A-Za-z0-9-'0-9a-zÀ-ÿ,#.\s]+$/.test(value)) {
    return true
  }
  return false
}

export const checkSku = (value) => {
  if (value === '' || /^[^0\W\u00F1\u00D1].[a-zA-Z0-9\-]{0,13}$/.test(value)) {
    console.log(value)
    return true
  }
  return false
}
export const space = (event) => {
  const keyCode = event.keyCode || event.which
  const keyValue = String.fromCharCode(keyCode)
  const valueBool = /^\S*$/.test(keyValue)
  if (!valueBool) {
    event.preventDefault()
  }
}
export const justNumbersString = (event) => {
  const keyCode = event.keyCode || event.which
  const keyValue = String.fromCharCode(keyCode)
  const valueBool = /^[A-Za-z0-9\pL\s]+$/.test(keyValue)
  if (!valueBool) {
    event.preventDefault()
  }
}
export const isFormatCorrectEmail = (value) => {
  if (/^[aA-zZ0-9].*\.?[_aA-zZ0-9]\@[A-Z0-9-]+\.([A-Z]{2,4}|[A-Z]+\.[A-Z]{2,4})$/i.test(value)) {
    return true
  }
  return false
}

export const apiRequestSms = (sms) => {
  let mesage = sms
  if (sms.includes('No se ha facilitado una URL')) {
    mesage = 'No se ha facilitado una URL válida.'
  }

  switch (mesage) {
    case `Value cannot be null. (Parameter 'Email duplicado')`:
      return 'El correo electrónico ya se encuentra registrado'
    case 'Input string was not in a correct format.':
      return 'Ocurrió un error'
    case 'Cannot get a numeric value from a text cell':
      return 'Valida archivo y formato del mismo e intenta de nuevo.'
    case `Value cannot be null. (Parameter 'No existe el Registro')`:
      return 'La categoría seleccionada no tiene atributos registrados.'
    case 'Object reference not set to an instance of an object.':
      return 'Ha ocurrido un error'
    case `Value cannot be null. (Parameter 'El seller que desea activar le falta completar el segundo registro')`:
      return 'No ha sido posible activar el seller debido a que le falta completar el segundo registro'
    case `Value cannot be null. (Parameter 'No es posible asignar un usuario por que la tienda ya ha sido activada.')`:
      return `No es posible asignar un usuario por que la tienda ya ha sido activada.`
    default:
      console.log(mesage)
      return mesage
  }
}

export const addLocalStore = (key, array) => {
  localStorage.setItem(key, array)
}

export const getLocalStore = (key) => {
  const array = localStorage.getItem(key)
  return array
}
export const getMonthDate = () => {
  return [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ]
}

export const currentDate = () => {
  const date = new Date()
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()

  return `${date.getFullYear()}-${month}-${
    date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
  }T${hours}:${minutes}:${seconds}`
}

export const removeDoubleSpaces = (str) => {
  return str.replace(/^\s+/, '').replace(/\s{2,}/g, ' ')
}

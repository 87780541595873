// eslint-disable-next-line import/prefer-default-export
export const routeBase = {
  dev: {
    url: '',
    urlMicroservicesProducts: '',
    urlUserManagement: '',
    urlMicroservicesOrders: '',
    CONCILIATOR_URL: '',
    CONCILIATOR_USER: '',
    CONCILIATOR_PASSWORD: '',
    marketingCampaign: '',
    MARKETING_CAMPAING_USER: '',
    MARKETING_CAMPAING_PASSWORD: '',
    routeFront: '',
    isMaintenance: 'true',
    routeShopify: {
      url: '',
      SCOrigen: 'dev',
      country: 'co',
      subscriptionId: '',
    },
    coupons: '',
    returns: '',
    sales: '',
    imageProcessor: '',
    imageProcessorApiKey: '',
    PAYMENTS: {
      PAYMENTS_API: '',
      PAYMENTS_OCP_APIM_SUBSCRIPTION_KEY: '',
      PAYMENTS_API_KEY: '',
      SC_ORIGIN: 'Development',
    },
    NOTIFICATION_CONFIGURATOR: '',
    googleApiKey: '',
    integrations: {
      token: '',
      secretKey: '',
    },
    hideReturns: false,
    hideSales: false,
    categorizer: '',
    dashboard: '',
  },
  qa: {
    url: '',
    urlMicroservicesProducts: '',
    urlUserManagement: '',
    urlMicroservicesOrders: '',
    CONCILIATOR_URL: '',
    CONCILIATOR_USER: '',
    CONCILIATOR_PASSWORD: '',
    marketingCampaign: '',
    MARKETING_CAMPAING_USER: '',
    MARKETING_CAMPAING_PASSWORD: '',
    routeFront: '',
    isMaintenance: 'true',
    routeShopify: {
      url: '',
      SCOrigen: 'qa',
      country: 'co',
      subscriptionId: '',
    },
    coupons: '',
    returns: '',
    sales: '',
    imageProcessor: '',
    imageProcessorApiKey: '',
    PAYMENTS: {
      PAYMENTS_API: '',
      PAYMENTS_OCP_APIM_SUBSCRIPTION_KEY: '',
      PAYMENTS_API_KEY: '',
      SC_ORIGIN: 'Qa',
    },
    NOTIFICATION_CONFIGURATOR: '',
    googleApiKey: '',
    integrations: {
      token: '',
      secretKey: '',
    },
    hideReturns: false,
    hideSales: false,
    categorizer: '',
    dashboard: '',
    soat: '',
    payments: '',
    soatApiKey: '',
    soatApiIV: '',
    cloudflareIp: '',
  },
  stag: {
    url: '',
    urlMicroservicesProducts: '',
    urlUserManagement: '',
    urlMicroservicesOrders: '',
    CONCILIATOR_URL: '',
    CONCILIATOR_USER: '',
    CONCILIATOR_PASSWORD: '',
    marketingCampaign: '',
    MARKETING_CAMPAING_USER: '',
    MARKETING_CAMPAING_PASSWORD: '',
    routeFront: '',
    isMaintenance: 'true',
    routeShopify: {
      url: '',
      SCOrigen: 'Staging',
      country: 'co',
      subscriptionId: '',
    },
    coupons: '',
    returns: '',
    sales: '',
    imageProcessor: '',
    imageProcessorApiKey: '',
    PAYMENTS: {
      PAYMENTS_API: '',
      PAYMENTS_OCP_APIM_SUBSCRIPTION_KEY: '',
      PAYMENTS_API_KEY: '',
      SC_ORIGIN: 'Staging',
    },
    NOTIFICATION_CONFIGURATOR: '',
    googleApiKey: '',
    integrations: {
      token: '',
      secretKey: '',
    },
    hideReturns: false,
    hideSales: false,
    categorizer: '',
    dashboard: '',
    soat: '',
    payments: '',
    soatApiKey: '',
    soatApiIV: '',
    cloudflareIp: '',
  },
  prod: {
    url: 'https://apigateway.luegopago.com/sellercenter',
    urlMicroservicesProducts: 'https://apigateway.luegopago.com/products/v1/Products',
    urlUserManagement: 'https://apigateway.luegopago.com/usermanagement',
    urlMicroservicesOrders: 'https://apigateway.luegopago.com/orders/api/v1/Order',
    CONCILIATOR_URL: 'https://apigateway.luegopago.com/checkout/api/checkout',
    CONCILIATOR_USER: 'user_front_checkout',
    CONCILIATOR_PASSWORD: 'dd80b61b-0d5e-4097-a368-e8772c7b1ebb',
    marketingCampaign: 'https://apigateway.luegopago.com/sellermarketing-campaigns',
    MARKETING_CAMPAING_USER: 'Prod',
    MARKETING_CAMPAING_PASSWORD: '85048801-102e-4c46-af33-e7c178bb3a9a',
    routeFront: '',
    isMaintenance: 'true',
    routeShopify: {
      url: 'https://api.credinet.co/luegopago/integration',
      SCOrigen: 'Production',
      country: 'co',
      subscriptionId: '',
    },
    coupons: 'https://apigateway.luegopago.com/api/coupon',
    returns: 'https://apigateway.luegopago.com/return-orders/api/',
    sales: 'https://apigateway.luegopago.com/api/sales',
    imageProcessor: 'https://apigateway.luegopago.com/image-processor/api/Process/ProcessImages',
    imageProcessorApiKey: '3feb5c00-232f-426f-b867-1f6bf583ba9e',
    PAYMENTS: {
      PAYMENTS_API: 'https://apigateway.luegopago.com/payments',
      PAYMENTS_OCP_APIM_SUBSCRIPTION_KEY: 'aedd2fdcd69d4be6acd18c571ed89e13',
      PAYMENTS_API_KEY: '3f49016d-9df7-4015-b634-f63e93467580',
      SC_ORIGIN: 'Production',
    },
    NOTIFICATION_CONFIGURATOR: 'https://luegopago.blob.core.windows.net/luegopago-uploads/NotificationConfigurator/PROD/1.2.0/NotificationConfigurator.js',
    googleApiKey: 'AIzaSyC_Pve4TxvhZG_wcvdxDRpV4QDk8YOHkB4',
    integrations: {
      token: 'THVlZ29QYWdv.Y2QyMzk2MmQtNTY2Ni00M2MzLWE2NmYtMzMwZmMwMDczODRi',
      secretKey: 'AervN2HSP8ay9WYGz3ZAzevvtW',
    },
    hideReturns: Boolean('false' === 'true'),
    hideSales: Boolean('false' === 'true'),
    categorizer: 'https://apigateway.luegopago.com/categorizer',
    dashboard: 'https://luegopago.blob.core.windows.net/luegopago-uploads/ReportsDashboard/PROD/1.0.0/dashboard-bundle-iife.js',
    soat: 'https://apigateway.luegopago.com/soat/api/v1',
    payments: 'https://apigateway.luegopago.com/payments/api/v2.0',
    soatApiKey: '',
    soatApiIV: '',
    cloudflareIp: 'https://www.cloudflare.com/cdn-cgi/trace',
  },

  default: {
    url: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/sellercenter',
    urlMicroservicesProducts:
      'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/products/v1/Products',
    urlMicroservicesOrders:
      'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/orders/api/v1/Order',
    urlUserManagement: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/usermanagement',
    CONCILIATOR_URL: 'http://localhost:3000/api/checkout',
    CONCILIATOR_USER: 'Qa',
    CONCILIATOR_PASSWORD: '2d6afed9-f9f5-483a-a987-c8ae1024d09f',
    marketingCampaign:
      'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/sellermarketing-campaigns',
    MARKETING_CAMPAING_USER: 'Development',
    MARKETING_CAMPAING_PASSWORD: '2d6afed9-f9f5-483a-a987-c8ae1024d09f',
    routeFront: 'https://qa-luegopago-front-sellercenter.luegopago.com',
    isMaintenance: false,
    routeShopify: {
      url: 'https://qa-luegopago-seller-integration-back-api.luegopago.com',
    },
    coupons: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/api/coupon',
    returns: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/return-orders/api/',
    sales: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/api/sales',
    imageProcessor:
      'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/image-processor/api/Process/ProcessImages',
    imageProcessorApiKey: 'ebcf9b48-1c4c-4409-af03-dc2ee3b8fe76',
    PAYMENTS: {
      PAYMENTS_API: 'https://devapi.credinet.co/luegopago/pagos',
      PAYMENTS_OCP_APIM_SUBSCRIPTION_KEY: 'b0dc8eb7924540e1913ab262b8500721',
      PAYMENTS_API_KEY: 'd8701b30-3f3d-40d1-896e-df2e8b97abc1',
      SC_ORIGIN: 'Development',
    },
    NOTIFICATION_CONFIGURATOR:
      'https://luegopago.blob.core.windows.net/luegopago-uploads/NotificationConfigurator/DEV/NotificationConfigurator.js',
    googleApiKey: 'AIzaSyD315GmuVfjOE8CgDEkY_kiYV-JD2RT1DM',
    integrations: {
      token: 'THVlZ29QYWdv.Y2QyMzk2MmQtNTY2Ni00M2MzLWE2NmYtMzMwZmMwMDczODRi',
      secretKey: 'AervN2HSP8ay9WYGz3ZAzevvtW',
    },
    hideReturns: false,
    hideSales: false,
    categorizer: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/categorizer',
    dashboard:
      'https://luegopago.blob.core.windows.net/luegopago-uploads/ReportsDashboard/DEV/dashboard-bundle-iife.js',
    soat: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/soat/api/v1',
    payments: 'https://qa-lpg-dnc-back-apigateway-k8.luegopago.com/Payments/api/v2.0',
    soatApiKey: '578b42b7cacd1ab89d732f0c1dee281e',
    soatApiIV: '4e6669be389376dc',
    cloudflareIp: 'https://www.cloudflare.com/cdn-cgi/trace',
  },
}

/* eslint-disable import/order */

import DashboardIcon from '@material-ui/icons/Dashboard'

import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import PaymentIcon from '@material-ui/icons/Payment'
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox'
import SettingsIcon from '@material-ui/icons/Settings'
import TocIcon from '@material-ui/icons/Toc'
import {
  OrderIcon,
  ProductIcon,
  ReportIcon,
  SetupIcon,
  UploadIcon,
  UserIcon,
  DownloadIcon,
  CategoriesIcon,
  CommissionsIcon,
  StroesIcon,
  AddIconBrans,
  bonusesIcon,
  paymentsIcon,
  configNotiIcon,
  ReturnIcon,
  SoatIcon,
} from 'components/icons/personalizedIcons'
import {
  ADMIN,
  DROPSHIPPER,
  SELLER,
  SUPER_ADMIN,
  ADMIN_SISTECREDITO,
  VOUCHER,
} from 'constants/rolesConst'
import { routesConst } from 'constants/routesConsts'
import Categories from 'views/Accounting/categories'
import Commisions from 'views/Accounting/commisions'
import ConfugurationGeneral from 'views/ConfugurationGeneral/ConfugurationGeneral'
import ConfigSeller from 'views/Accounting/configSeller'
import FinancialSummary from 'views/Accounting/financialSummary'
import Error404 from 'views/Error404/Error404'
import Error500 from 'views/Error500/Error500'
import StoreCommission from 'views/GeneralConfiguration/storeCommission'
import StoreProfile from 'views/GeneralConfiguration/storeProfile'
import UserManagement from 'views/GeneralConfiguration/userManagement'
import AssessmentIcon from '@material-ui/icons/Assessment'
import Orders from 'views/Orders'
import Approvals from 'views/Stores/Approvals/approvals'
import PendingApproval from 'views/Stores/Approvals/PendingApproval'
import Products from 'views/Products'
import ProductsShopify from 'views/ProductsShopify'
import Integrations from 'views/Integrations'
import SyncIcon from '@material-ui/icons/Sync'
import Bonuses from 'views/Bonus/Bonuses'

import ProductStepper from 'views/Products/ProductStepper'
import AccountStatus from 'views/Reports/AccountStatus'
import SaleReport from 'views/SaleReport'
import Transaction from 'views/Transaction'
import Conciliator from 'views/Conciliator'
import DetailSellerStore from 'views/StoreDetail/DetailSellerStore'
import CreateBonus from 'views/Bonus/CreateBonus'

import Stores from 'views/Stores'
import productsBySeller from 'views/Stores/productsByStore'
import ImportProducts from 'views/UploadProducts'
import ViewOrder from 'views/ViewOrder'
import UploadMails from 'views/UploadProducts/UploadMails'
import CreateCoupons from 'views/Coupons/CreateCoupons'
import { EnablingMethods } from 'views/PaymentMethods/enablingMethods'
import configNotificationsForm from 'views/ConfigNotifications/configNotifications'
import Brands from 'views/Brands/Brands'
import Returns from 'views/Returns'
import { routeBase } from 'constants/environments.constants'
import SalesManagement from 'views/Sales'
import { Check, LocalOffer } from '@material-ui/icons'
import ReportsDashboard from 'views/ReportsDashboard'

import SoatOrders from 'views/Soat'

const layout = '/admin'
export default {
  [DROPSHIPPER.toLowerCase()]: [
    {
      path: routesConst.salesSummary,
      name: 'Resumen de ventas',
      component: ReportsDashboard,
      layout,
      icon: ReportIcon,
      hide: !routeBase[process.env.REACT_APP_ENVIRONMENT].dashboard,
    },
    {
      path: routesConst.orders,
      name: 'Pedidos',
      component: Orders,
      layout,
      icon: OrderIcon,
    },
    {
      path: routesConst.returns,
      name: 'Devoluciones',
      component: Returns,
      layout,
      icon: ReturnIcon,
      hide: routeBase[process.env.REACT_APP_ENVIRONMENT].hideReturns,
    },
    {
      collapse: true,
      name: 'Productos',
      state: 'productsCollapse',
      icon: ProductIcon,
      views: [
        {
          path: routesConst.addProduct,
          name: 'Agregar producto',
          component: ProductStepper,
          layout,
        },
        {
          path: routesConst.importProducts,
          name: 'Importar productos',
          component: ImportProducts,
          layout,
        },
        {
          path: routesConst.updateProducts,
          name: 'Actualizar productos',
          component: ImportProducts,
          layout,
        },
        {
          path: routesConst.products,
          name: 'Gestionar productos',
          component: Products,
          child: ['edit-product', 'copy-product'],
          layout,
        },
      ],
    },
    {
      path: routesConst.userManagement,
      name: 'Usuarios',
      component: UserManagement,
      layout,
      icon: UserIcon,
    },
    {
      path: routesConst.storeProfile,
      name: 'Configurar tienda',
      component: StoreProfile,
      layout,
      icon: SetupIcon,
      withParam: true,
    },
    {
      path: routesConst.integrations,
      name: 'Integraciones',
      component: Integrations,
      child: ['shopify-products'],
      layout: '/admin',
      icon: SyncIcon,
    },
    {
      path: routesConst.saleReport,
      name: 'Reporte de ventas',
      component: SaleReport,
      layout,
      icon: AssessmentIcon,
    },
    {
      path: routesConst.salesManagement,
      name: 'Promociones',
      component: SalesManagement,
      layout,
      icon: LocalOffer,
      hide: routeBase[process.env.REACT_APP_ENVIRONMENT].hideSales,
    },
    {
      path: routesConst.createCoupon,
      name: 'Cupones',
      component: CreateCoupons,
      layout,
      icon: LibraryAddCheckIcon,
    },
    {
      path: routesConst.viewOrder,
      name: 'Detalle del pedido',
      component: ViewOrder,
      layout,
      icon: DashboardIcon,
      withParam: true,
      hide: true,
    },
    {
      path: routesConst.shopify,
      name: 'Shopify',
      component: ProductsShopify,
      layout,
      icon: DashboardIcon,
      withParam: false,
      hide: true,
    },
    {
      path: routesConst.editProduct,
      name: 'Editar Productos',
      component: ProductStepper,
      pattern: Products,
      layout,
      icon: DashboardIcon,
      withParam: true,
      hide: true,
    },
    {
      path: routesConst.copyProduct,
      name: 'Copiar productos',
      component: ProductStepper,
      layout,
      icon: DashboardIcon,
      withParam: true,
      hide: true,
    },
    {
      path: routesConst.storeCommission,
      name: 'Comisiones tienda',
      component: StoreCommission,
      layout,
      hide: true,
    },
    {
      path: routesConst.error404,
      name: 'Error 404',
      component: Error404,
      layout,
      hide: true,
    },
    {
      path: routesConst.error500,
      name: 'Error 500',
      component: Error500,
      layout,
      hide: true,
    },
  ],
  [SELLER.toLowerCase()]: [
    {
      path: routesConst.orders,
      name: 'Pedidos',
      component: Orders,
      layout,
      icon: OrderIcon,
    },
    {
      collapse: true,
      path: routesConst.products,
      name: 'Productos',
      layout,
      state: 'productsCollapse',
      icon: ProductIcon,
      views: [
        {
          path: routesConst.addProduct,
          name: 'Agregar producto',
          component: ProductStepper,
          layout,
        },
        {
          path: routesConst.importProducts,
          name: 'Importar productos',
          component: ImportProducts,
          layout,
        },
        {
          path: routesConst.updateProducts,
          name: 'Actualizar productos',
          component: ImportProducts,
          layout,
        },
        {
          path: routesConst.products,
          name: 'Gestionar productos',
          component: Products,
          child: ['edit-product', 'copy-product'],
          layout,
        },
      ],
    },
    {
      path: routesConst.editProduct,
      name: 'Editar Productos',
      component: ProductStepper,
      pattern: Products,
      layout,
      icon: DashboardIcon,
      withParam: true,
      hide: true,
    },
    {
      path: routesConst.error404,
      name: 'Error 404',
      component: Error404,
      layout,
      hide: true,
    },
    {
      path: routesConst.error500,
      name: 'Error 500',
      component: Error500,
      layout,
      hide: true,
    },
  ],
  [ADMIN.toLowerCase()]: [
    {
      path: routesConst.orders,
      name: 'Pedidos',
      component: Orders,
      layout,
      icon: OrderIcon,
    },
    {
      collapse: true,
      name: 'Productos',
      state: 'productsCollapse',
      icon: ProductIcon,
      views: [
        {
          path: routesConst.addProduct,
          name: 'Agregar producto',
          component: ProductStepper,
          layout,
        },
        {
          path: routesConst.updateProducts,
          name: 'Importar productos',
          component: ImportProducts,
          layout,
        },
        {
          path: routesConst.importProducts,
          name: 'Actualizar productos',
          component: ImportProducts,
          layout,
        },
        {
          path: routesConst.products,
          name: 'Gestionar productos',
          component: Products,
          child: ['edit-product', 'copy-product'],
          layout,
          hide: true,
        },
      ],
    },
    {
      path: routesConst.userManagement,
      name: 'Usuarios',
      component: UserManagement,
      layout,
      icon: UserIcon,
    },
    {
      path: routesConst.storeProfile,
      name: 'Configuración de la tienda',
      component: StoreProfile,
      layout,
      icon: SetupIcon,
    },
    {
      path: routesConst.integrations,
      name: 'Integraciones',
      component: Integrations,
      child: ['shopify-products'],
      layout: '/admin',
      icon: SyncIcon,
    },
    {
      path: routesConst.saleReport,
      name: 'Reporte de ventas',
      component: SaleReport,
      layout,
      icon: AssessmentIcon,
    },
    {
      path: routesConst.accountStatus,
      name: 'Informes',
      component: AccountStatus,
      layout,
      icon: ReportIcon,
    },
    {
      path: routesConst.viewOrder,
      name: 'Detalle del pedido',
      component: ViewOrder,
      layout,
      icon: DashboardIcon,
      withParam: true,
      hide: true,
    },
    {
      path: routesConst.editProduct,
      name: 'Editar Productos',
      component: ProductStepper,
      pattern: Products,
      layout,
      icon: DashboardIcon,
      withParam: true,
      hide: true,
    },
    {
      path: routesConst.copyProduct,
      name: 'Copiar productos',
      component: ProductStepper,
      layout,
      icon: DashboardIcon,
      withParam: true,
      hide: true,
    },
    {
      path: routesConst.error404,
      name: 'Error 404',
      component: Error404,
      layout,
      hide: true,
    },
    {
      path: routesConst.error500,
      name: 'Error 500',
      component: Error500,
      layout,
      hide: true,
    },
  ],
  [SUPER_ADMIN.toLowerCase()]: [
    {
      path: routesConst.salesSummary,
      name: 'Resumen de ventas',
      component: ReportsDashboard,
      layout,
      icon: ReportIcon,
      hide: !routeBase[process.env.REACT_APP_ENVIRONMENT].dashboard,
    },
    {
      path: routesConst.stores,
      name: 'Mis tiendas',
      component: Stores,
      layout,
      icon: StroesIcon,
    },
    {
      path: routesConst.orders,
      name: 'Pedidos',
      component: Orders,
      layout,
      icon: OrderIcon,
    },
    {
      path: routesConst.soatOrders,
      name: 'SOAT',
      component: SoatOrders,
      layout,
      icon: SoatIcon,
    },
    {
      path: routesConst.returns,
      name: 'Devoluciones',
      component: Returns,
      layout,
      icon: ReturnIcon,
      hide: routeBase[process.env.REACT_APP_ENVIRONMENT].hideReturns,
    },
    {
      path: routesConst.viewOrder,
      name: 'Detalle del pedido',
      component: ViewOrder,
      layout,
      icon: DashboardIcon,
      withParam: true,
      hide: true,
    },
    {
      path: routesConst.productsBySeller,
      name: 'productos de tienda',
      component: productsBySeller,
      layout,
      icon: StroesIcon,
      withParam: true,
      hide: true,
    },
    {
      path: routesConst.approvals,
      name: 'Aprobaciones',
      component: Approvals,
      layout,
      icon: StroesIcon,
      hide: true,
    },
    {
      path: routesConst.configSeller,
      name: 'Configuración de seller',
      component: ConfigSeller,
      layout,
      icon: UserIcon,
    },
    {
      path: routesConst.financialSummary,
      name: 'Exportar consolidado',
      component: FinancialSummary,
      layout,
      icon: DownloadIcon,
    },
    {
      path: routesConst.categories,
      name: 'Categorías',
      component: Categories,
      layout,
      icon: CategoriesIcon,
    },
    {
      path: routesConst.brands,
      name: 'Agregar Marcas',
      component: Brands,
      layout,
      icon: AddIconBrans,
    },
    {
      path: routesConst.commisions,
      name: 'Comisiones',
      component: Commisions,
      layout,
      icon: CommissionsIcon,
    },
    {
      path: routesConst.enabledMethods,
      name: 'Medios de pago',
      component: EnablingMethods,
      layout,
      icon: paymentsIcon,
    },
    {
      path: routesConst.generalConfiguration,
      name: 'Configuración general',
      component: ConfugurationGeneral,
      layout,
      icon: SettingsIcon,
    },
    {
      path: routesConst.saleReport,
      name: 'Reporte de ventas',
      component: SaleReport,
      layout,
      icon: AssessmentIcon,
    },
    {
      path: routesConst.integrations,
      name: 'Integraciones',
      component: Integrations,
      child: ['shopify-products'],
      layout: '/admin',
      icon: SyncIcon,
    },
    {
      path: routesConst.sendGrid,
      name: 'Envio de correos',
      component: UploadMails,
      layout,
      icon: UploadIcon,
    },
    {
      collapse: true,
      name: 'Informes',
      icon: ReportIcon,
      state: 'reportsCollapse',
      views: [
        {
          path: routesConst.transaction,
          name: 'Transacciones',
          component: Transaction,
          layout,
          icon: AccountBalanceWalletIcon,
        },
        {
          path: routesConst.conciliator,
          name: 'Conciliador',
          component: Conciliator,
          layout,
          icon: AccountBalanceWalletIcon,
        },
      ],
    },

    {
      path: routesConst.storeDetail,
      name: 'Detalle de tienda',
      component: DetailSellerStore,
      layout,
      icon: DashboardIcon,
      withParam: true,
      hide: true,
    },
    {
      path: routesConst.createBonus,
      name: 'bonus',
      component: CreateBonus,
      layout,
      hide: true,
    },
    {
      path: routesConst.approveProduct,
      name: 'Aprobar Productos',
      component: ProductStepper,
      layout,
      icon: DashboardIcon,
      withParam: true,
      hide: true,
    },
    {
      path: routesConst.pendingApproval,
      name: 'Pendiente de aprobaciones',
      component: PendingApproval,
      layout,
      icon: DashboardIcon,
      withParam: true,
      hide: true,
    },
    {
      path: routesConst.error404,
      name: 'Error 404',
      component: Error404,
      layout,
      hide: true,
    },
    {
      path: routesConst.error500,
      name: 'Error 500',
      component: Error500,
      layout,
      hide: true,
    },
    {
      path: routesConst.salesManagement,
      name: 'Promociones',
      component: SalesManagement,
      layout,
      icon: LocalOffer,
      hide: routeBase[process.env.REACT_APP_ENVIRONMENT].hideSales,
    },
    {
      path: routesConst.createCoupon,
      name: 'Cupones',
      component: CreateCoupons,
      layout,
      icon: LibraryAddCheckIcon,
    },
    {
      path: routesConst.configNotifications,
      name: 'Notificaciones',
      component: configNotificationsForm,
      layout,
      icon: configNotiIcon,
    },
    {
      path: routesConst.adminEditProduct,
      name: 'Productos admin',
      component: ProductStepper,
      layout,
      icon: DashboardIcon,
      withParam: true,
      hide: true,
    },
  ],
  [ADMIN_SISTECREDITO.toLowerCase()]: [
    {
      path: routesConst.orders,
      name: 'Pedidos',
      component: Orders,
      layout,
      icon: OrderIcon,
    },
    {
      path: routesConst.viewOrder,
      name: 'Detalle del pedido',
      component: ViewOrder,
      layout,
      icon: DashboardIcon,
      withParam: true,
      hide: true,
    },
  ],
  [VOUCHER.toLowerCase()]: [],
  all: [],
}

/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'

import {
  Grid,
  InputLabel,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import {
  getDepartments,
  getCities,
  getSellerFormOptions,
  getCustomerType,
} from '../../../services/departamentAndCities/services'
import { getIntegrations } from '../../../services/integrations/integrations.service'
import styles from '../generalConfig.styles'

import { onKeyPressJustNumbers, isAlphanumeric } from 'utils/utils'
import { removeDoubleSpaces } from 'utils/utils'

const Form = ({ data, setData, errors, register, cites, setCites }) => {
  const classes = styles()
  const [isLoading, setIsLoading] = useState(false)
  const [departaments, setDepartaments] = useState([])
  const [personsTypes, setPersonsTypes] = useState([])
  const [identificationsTypes, setIdentificationsTypes] = useState([])
  const [regimesTypes, setRegimesTypes] = useState([])
  const [valueDepartment, setValueDepartments] = useState({})
  const [valuePersonsTypes, setValuePersonsTypes] = useState({})
  const [valueIdentificationsTypes, setValueIdentificationsTypes] = useState({})
  const [integrations, setIntegrations] = useState([])

  const defaultPropsPersonsTypes = {
    options: personsTypes,
    getOptionLabel: (option) => (option.name === null ? '' : option.name),
  }

  const defaultPropsDepartaments = {
    options: departaments,
    getOptionLabel: (option) => (option.name === null ? '' : option.name),
  }

  const defaultPropsCites = {
    options: cites,
    getOptionLabel: (option) => (option.name === null ? '' : option.name),
  }

  const defaultPropsIdentificationsTypes = {
    options: identificationsTypes,
    getOptionLabel: (option) => (option.name === null ? '' : option.name),
  }

  const defaultPropsRegimesTypes = {
    options: regimesTypes,
    getOptionLabel: (option) => (option.description === null ? '' : option.description),
  }
  const getObjectCampo = (id, array) => {
    return array.find((object) => object.id === id)
  }

  const handleChange = (event) => {
    if (isAlphanumeric(event.target.value.trimStart())) {
      return true
    }
    return false
  }

  const preventDefault = (e) => {
    e.preventDefault()
  }

  useEffect(() => {
    getDepartments(setDepartaments, setIsLoading)
    getIntegrations(setIntegrations, setIsLoading)
    getSellerFormOptions(setPersonsTypes, setIdentificationsTypes, setIsLoading)
    getCustomerType(setRegimesTypes, setIsLoading, data.personalData.typeOfPersonId)
  }, [])
  useEffect(() => {
    if (!valueDepartment) {
      setCites([])
    }
    if (valueDepartment && Object.keys(valueDepartment).length !== 0) {
      getCities(setCites, valueDepartment.id, setIsLoading)
    }
  }, [valueDepartment])

  const onChangeIntegrations = (event, integration) => {
    if (event.target.checked) {
      setData({
        ...data,
        integrations: [...data?.integrations, integration],
      })
    } else {
      setData({
        ...data,
        integrations: data?.integrations.filter(
          (typeIntegration) => typeIntegration.id !== integration.id
        ),
      })
    }
  }
  return (
    <>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <Grid container justify="space-between" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Ingresa los siguientes datos</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel className={classes.topInputLabel} error={errors.typeOfPersonId}>
            Tipo de persona
          </InputLabel>
          <Autocomplete
            {...defaultPropsPersonsTypes}
            id="typeOfPersonId"
            name="typeOfPersonId"
            disabled
            className={classes.autocompleteMargin}
            value={
              data?.personalData?.typeOfPersonId === 18
                ? { id: 18, name: 'Natural' }
                : data?.personalData?.typeOfPersonId === 19
                ? { id: 19, name: 'Jurídica' }
                : {}
            }
            helperText={valuePersonsTypes}
            onChange={(event, newValue) => {
              setValuePersonsTypes(newValue)
              setData({
                ...data,
                personalData: {
                  ...data.personalData,
                  typeOfPersonId: newValue?.id || 0,
                },
              })
            }}
            renderInput={(params) => (
              <TextField
                id="typeOfPersonId"
                name="typeOfPersonId"
                {...params}
                inputRef={register({
                  required: 'Campo requerido',
                })}
                placeholder="Seleccione..."
                error={!!errors.typeOfPersonId}
                helperText={errors?.typeOfPersonId?.message}
                className={classes.autocompleteDetailProduct}
                label=""
                margin="normal"
                variant="outlined"
              />
            )}
          />
        </Grid>
        {data?.personalData?.typeOfPersonId === 18 ? (
          <>
            <Grid item xs={12} md={6}>
              <InputLabel className={classes.topInputLabel} error={errors.fisrtName}>
                Primer Nombre
              </InputLabel>
              <TextField
                name="fisrtName"
                variant="outlined"
                fullWidth
                error={errors.fisrtName}
                inputRef={register({
                  required: 'Campo obligatorio',
                  pattern: {
                    value: /^[A-Za-z0-9-'0-9a-zÀ-ÿ,\s*/%$/.,&-+#!¡]+$/,
                    message: 'Sólo se permite valores alfanuméricos',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Máximo 50 letras',
                  },
                  minLength: {
                    value: 2,
                    message: 'Minimo 2 letras',
                  },
                })}
                onChange={(event) => {
                  handleChange(event)
                  setData({
                    ...data,
                    personalData: {
                      ...data.personalData,
                      fisrtName: event.target.value.trimStart(),
                    },
                  })
                }}
                inputProps={{
                  maxLength: 50,
                }}
                value={data.personalData?.fisrtName}
                onPaste={preventDefault}
                helperText={errors.fisrtName?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel className={classes.topInputLabel} error={errors.secondName}>
                Segundo Nombre (opcional)
              </InputLabel>
              <TextField
                name="secondName"
                variant="outlined"
                fullWidth
                error={errors.secondName}
                inputRef={register({
                  pattern: {
                    value: /^[A-Za-z0-9-'0-9a-zÀ-ÿ,\s*/%$/.,&-+#!¡]+$/,
                    message: 'Sólo se permite valores alfanuméricos',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Máximo 50 letras',
                  },
                  minLength: {
                    value: 2,
                    message: 'Minimo 2 letras',
                  },
                })}
                inputProps={{
                  maxLength: 50,
                }}
                onChange={(event) => {
                  handleChange(event)
                  setData({
                    ...data,
                    personalData: {
                      ...data.personalData,
                      secondName: event.target.value.trimStart(),
                    },
                  })
                }}
                value={data.personalData?.secondName || ''}
                onPaste={preventDefault}
                helperText={errors.secondName?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel className={classes.topInputLabel} error={errors.lastName}>
                Apellidos
              </InputLabel>
              <TextField
                name="lastName"
                fullWidth
                variant="outlined"
                error={errors.lastName}
                inputRef={register({
                  required: 'Campo obligatorio',
                  pattern: {
                    value: /^[A-Za-z0-9-'0-9a-zÀ-ÿ,\s*/%$/.,&-+#!¡]+$/,
                    message: 'Sólo se permite valores alfanuméricos',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Máximo 50 letras',
                  },
                  minLength: {
                    value: 2,
                    message: 'Minimo 2 letras',
                  },
                })}
                inputProps={{
                  maxLength: 50,
                }}
                helperText={errors.lastName && 'Campo obligatorio'}
                onPaste={preventDefault}
                onChange={(event) => {
                  handleChange(event)
                  setData({
                    ...data,
                    personalData: {
                      ...data.personalData,
                      lastName: event.target.value.trimStart(),
                    },
                  })
                }}
                value={data.personalData.lastName}
              />
            </Grid>
            <Grid item xs={12} md={data?.personalData?.typeOfPersonId === 18 ? 12 : 6}>
              <InputLabel className={classes.topInputLabel} error={errors.storeName}>
                {data?.personalData?.typeOfPersonId === 18 ? 'Nombre comercial' : 'Razón social'}
              </InputLabel>
              <TextField
                name="storeName"
                variant="outlined"
                fullWidth
                error={errors.storeName}
                inputRef={register({
                  required: 'Campo obligatorio',
                  pattern: /^[A-Za-z0-9-'0-9a-zÀ-ÿ,\s*/%$/.,&-+#!¡]+$/,
                })}
                onChange={(event) => {
                  handleChange(event)
                  setData({
                    ...data,
                    personalData: {
                      ...data.personalData,
                      storeName: event.target.value.trimStart(),
                      commercialName: event.target.value.trimStart(),
                    },
                  })
                }}
                inputProps={{
                  maxLength: 50,
                }}
                value={data.personalData.storeName || ''}
                onPaste={preventDefault}
                helperText={errors.storeName && 'Campo obligatorio'}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel className={classes.topInputLabel} error={errors.identificationTypeId}>
                Tipo de documento
              </InputLabel>
              <Autocomplete
                {...defaultPropsIdentificationsTypes}
                id="identificationTypeId"
                name="identificationTypeId"
                className={classes.autocompleteMargin}
                value={
                  getObjectCampo(data.personalData.identificationTypeId, identificationsTypes) || {}
                }
                helperText={errors?.identificationTypeId?.message}
                onChange={(event, newValue) => {
                  setValueIdentificationsTypes(newValue)
                  if (newValue?.id === 0) {
                    setData({
                      ...data,
                      personalData: {
                        ...data.personalData,
                        identification: '',
                      },
                    })
                  }
                  setData({
                    ...data,
                    personalData: {
                      ...data.personalData,
                      identificationTypeId: newValue?.id || 0,
                    },
                  })
                }}
                renderInput={(params) => (
                  <TextField
                    id="identificationTypeId"
                    name="identificationTypeId"
                    {...params}
                    inputRef={register({
                      required: 'Campo requerido',
                    })}
                    placeholder="Seleccione..."
                    error={!!errors.identificationTypeId}
                    helperText={errors?.identificationTypeId?.message}
                    className={classes.autocompleteDetailProduct}
                    value={valueIdentificationsTypes}
                    label=""
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </>
        ) : null}

        {data?.personalData?.typeOfPersonId === 19 ? (
          <>
            <Grid item xs={12} md={data?.personalData?.typeOfPersonId === 18 ? 12 : 6}>
              <InputLabel className={classes.topInputLabel} error={errors.storeName}>
                {data?.personalData?.typeOfPersonId === 18 ? 'Nombre comercial' : 'Razón social'}
              </InputLabel>
              <TextField
                name="storeName"
                variant="outlined"
                fullWidth
                error={errors.storeName}
                inputRef={register({
                  required: 'Campo obligatorio',
                  pattern: /^[A-Za-z0-9-'0-9a-zÀ-ÿ,\s*/%$/.,&-+#!¡]+$/,
                })}
                onChange={(event) => {
                  handleChange(event)
                  setData({
                    ...data,
                    personalData: {
                      ...data.personalData,
                      storeName: event.target.value.trimStart(),
                    },
                  })
                }}
                inputProps={{
                  maxLength: 50,
                }}
                value={data.personalData.storeName || ''}
                onPaste={preventDefault}
                helperText={errors.storeName && 'Campo obligatorio'}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.topInputLabel} error={errors.commercialName}>
                Nombre comercial
              </InputLabel>
              <TextField
                name="commercialName"
                fullWidth
                variant="outlined"
                error={errors.commercialName}
                inputRef={register({
                  required: 'Required',
                  pattern: /^[A-Za-z0-9-'0-9a-zÀ-ÿ,\s*/%$/.,&-+#!¡]+$/,
                })}
                inputProps={{
                  maxLength: 50,
                }}
                helperText={errors.commercialName && 'Campo obligatorio'}
                onPaste={preventDefault}
                onChange={(event) => {
                  handleChange(event)
                  setData({
                    ...data,
                    personalData: {
                      ...data.personalData,
                      commercialName: event.target.value.trimStart(),
                    },
                  })
                }}
                value={data.personalData.commercialName}
              />
            </Grid>
          </>
        ) : null}
        {data?.personalData?.typeOfPersonId !== null ? (
          <Grid item xs={12} md={6}>
            <InputLabel className={classes.topInputLabel} error={errors.identification}>
              Número de documento
            </InputLabel>
            <TextField
              name="identification"
              id="identification"
              variant="outlined"
              disabled={
                data.personalData.identificationTypeId === 0 &&
                data.personalData.typeOfPersonId === 18
              }
              fullWidth
              inputProps={{
                maxLength: 11,
              }}
              error={errors.identification}
              inputRef={register({
                required: 'Campo requerido',
                pattern: {
                  value:
                    data.personalData.identificationTypeId === 23 ||
                    data.personalData.identificationTypeId === 22
                      ? '/^[A-Za-z0-9]+$/i'
                      : data.personalData.identificationTypeId === 21
                      ? /^[0-9]+$/i
                      : data.personalData.identificationTypeId === 20
                      ? /^[0-9-]+$/i
                      : '',
                  message:
                    data.personalData.identificationTypeId === 23 ||
                    data.personalData.identificationTypeId === 22
                      ? 'Sólo números'
                      : data.personalData.identificationTypeId === 21
                      ? 'Sólo números'
                      : data.personalData.identificationTypeId === 20
                      ? 'Sólo números con guiones'
                      : '',
                },
              })}
              onChange={(event) =>
                setData({
                  ...data,
                  personalData: {
                    ...data.personalData,
                    identification: event.target.value.trimStart(),
                  },
                })
              }
              value={data.personalData.identification || ''}
              helperText={!errors.identification ? null : errors.identification.message}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} md={6}>
          <InputLabel className={classes.topInputLabel} error={errors.sellerTypeId}>
            Tipo de Régimen
          </InputLabel>
          <Autocomplete
            {...defaultPropsRegimesTypes}
            id="sellerTypeId"
            name="sellerTypeId"
            className={classes.autocompleteMargin}
            value={getObjectCampo(data.personalData.sellerTypeId || 0, regimesTypes) || {}}
            helperText={errors?.sellerTypeId?.message}
            onChange={(event, newValue) => {
              setData({
                ...data,
                personalData: {
                  ...data.personalData,
                  sellerTypeId: newValue?.id || 0,
                },
              })
            }}
            renderInput={(params) => (
              <TextField
                id="sellerTypeId"
                name="sellerTypeId"
                {...params}
                inputRef={register({
                  required: 'Campo requerido',
                })}
                placeholder="Seleccione..."
                error={!!errors.sellerTypeId}
                helperText={errors?.sellerTypeId?.message}
                className={classes.autocompleteDetailProduct}
                label=""
                value={data.personalData.sellerTypeId}
                margin="normal"
                variant="outlined"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel className={classes.topInputLabel} error={errors.storePhone}>
            Número de teléfono
          </InputLabel>
          <TextField
            name="storePhone"
            variant="outlined"
            fullWidth
            error={errors.storePhone}
            inputRef={register({
              required: 'Campo obligatorio',
              pattern: {
                value: /^([0-9]+(?:[\][0-9]*)?|\.[0-9]+)$/i,
                message: 'Solo acepta números',
              },
              maxLength: {
                value: 12,
                message: 'Máximo 12 números',
              },
              minLength: {
                value: 7,
                message: 'Minimo 7 números',
              },
            })}
            inputProps={{
              maxLength: 12,
              minLength: 7,
            }}
            onKeyPress={onKeyPressJustNumbers}
            onChange={(event) =>
              setData({
                ...data,
                personalData: {
                  ...data.personalData,
                  storePhone: event.target.value.trimStart(),
                },
              })
            }
            value={data.personalData.storePhone}
            helperText={errors?.storePhone?.message}
          />
        </Grid>

        <Grid item xs={12} md={data?.personalData?.typeOfPersonId === 18 ? 12 : 6}>
          <InputLabel className={classes.topInputLabel} error={errors.storeEmail}>
            Correo electrónico
          </InputLabel>
          <TextField
            name="storeEmail"
            fullWidth
            variant="outlined"
            disabled
            error={errors.storeEmail}
            inputRef={register({
              required: 'Required',
              pattern:
                /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
            })}
            inputProps={{
              maxLength: 50,
            }}
            helperText={errors.storeEmail && 'Ingrese un correo válido'}
            onChange={(event) =>
              setData({
                ...data,
                personalData: {
                  ...data.personalData,
                  storeEmail: event.target.value.trimStart(),
                },
              })
            }
            value={data.personalData.storeEmail || ''}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel error={!!errors.departament} className={classes.topInputLabel}>
            Departamento
          </InputLabel>
          <Autocomplete
            {...defaultPropsDepartaments}
            id="department"
            name="department"
            className={classes.autocompleteMargin}
            value={getObjectCampo(data.personalData.departmentId, departaments) || {}}
            helperText={errors?.department?.message}
            onChange={(event, newValue) => {
              setData({
                ...data,
                personalData: {
                  ...data.personalData,
                  cityId: 0,
                },
              })
              setValueDepartments(newValue)
              setData({
                ...data,
                personalData: {
                  ...data.personalData,
                  departmentId: newValue?.id || 0,
                },
              })
            }}
            renderInput={(params) => (
              <TextField
                id="departament"
                name="departament"
                {...params}
                inputRef={register({
                  required: 'Campo requerido',
                })}
                error={!!errors.departament}
                placeholder="Seleccione..."
                value={data.personalData.departament || ''}
                helperText={errors?.departament?.message}
                margin="normal"
                variant="outlined"
                className={classes.autocompleteDetailProduct}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel error={!!errors.city} className={classes.topInputLabel}>
            Ciudad
          </InputLabel>
          <Autocomplete
            {...defaultPropsCites}
            id="citiesid"
            name="citiesid"
            className={classes.autocompleteMargin}
            value={getObjectCampo(data.personalData.cityId || 0, cites || []) || {}}
            onChange={(event, newValue) => {
              setData({
                ...data,
                personalData: {
                  ...data.personalData,
                  cityId: newValue?.id || 0,
                },
              })
            }}
            renderInput={(params) => (
              <TextField
                id="city"
                name="city"
                {...params}
                inputRef={register({
                  required: 'Campo requerido',
                })}
                error={!!errors.city}
                placeholder="Seleccione..."
                value={data.personalData.city}
                helperText={errors?.city?.message}
                margin="normal"
                variant="outlined"
                className={classes.autocompleteDetailProduct}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={classes.topInputLabel} error={errors.address}>
            Dirección
          </InputLabel>
          <TextField
            name="address"
            fullWidth
            variant="outlined"
            error={errors.address}
            inputRef={register({
              required: true,
              minLength: {
                value: 5,
                message: 'Ingrese una dirección válida',
              },
            })}
            helperText={errors.address && 'Ingrese una dirección válida'}
            onChange={(event) =>
              setData({
                ...data,
                personalData: {
                  ...data.personalData,
                  address: removeDoubleSpaces(event.target.value),
                },
              })
            }
            value={data.personalData.address || ''}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {integrations.map((integration) => {
            return (
              <FormControlLabel
                key={integration.id}
                className={classes.positionElements}
                control={
                  <Switch
                    checked={data.integrations.find(
                      (typeIntegration) => typeIntegration.id === integration.id
                    )}
                    onChange={(event) => onChangeIntegrations(event, integration)}
                    name="integration"
                    color="primary"
                    size="small"
                  />
                }
                label={<Typography>{integration.name}</Typography>}
              />
            )
          })}
        </Grid>
        <Grid item xs={12} md={6}>
          {data.integrations.some((item) => item.id === 1) && (
            <FormControlLabel
              className={classes.positionElements}
              control={
                <Switch
                  checked={data.personalData.isWeightGrams}
                  onChange={(event) =>
                    setData((prev) => ({
                      ...prev,
                      personalData: {
                        ...prev.personalData,
                        isWeightGrams: event.target.checked,
                      },
                    }))
                  }
                  name="integration"
                  color="primary"
                  size="small"
                />
              }
              label={<Typography>Peso en gramos</Typography>}
            />
          )}
        </Grid>
      </Grid>
      {/* </form> */}
    </>
  )
}

export default Form
